// import { Link } from "gatsby";
// import { css } from "@emotion/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import sidebarStyles from "./Sidebar.module.scss";

import AniLink from "gatsby-plugin-transition-link/AniLink";

class Sidebar extends React.Component {
  render() {
    return (
      <div
        className={cx(
          sidebarStyles.sidebar,
          this.props.isOpen && sidebarStyles.open
        )}
      >
        <ul>
          <li>
            <AniLink
              fade
              duration={1}
              className={sidebarStyles.about}
              onClick={this.props.toggleSidebar}
              activeClassName={sidebarStyles.active}
              to="/about/"
            >
              <FontAwesomeIcon icon="user" size="sm" />
            </AniLink>
          </li>
          <li>
            <AniLink
              fade
              duration={1}
              className={sidebarStyles.projects}
              onClick={this.props.toggleSidebar}
              activeClassName={sidebarStyles.active}
              to="/projects/"
            >
              <FontAwesomeIcon icon={["fas", "folder-open"]} size="sm" />
            </AniLink>
          </li>
          <li>
            <AniLink
              fade
              duration={1}
              className={sidebarStyles.skills}
              onClick={this.props.toggleSidebar}
              activeClassName={sidebarStyles.active}
              to="/skills/"
            >
              <FontAwesomeIcon icon="cog" size="sm" spin={true} />
            </AniLink>
          </li>
          <li>
            <AniLink
              fade
              duration={1}
              className={sidebarStyles.contact}
              onClick={this.props.toggleSidebar}
              activeClassName={sidebarStyles.active}
              to="/contact/"
            >
              <FontAwesomeIcon icon={["fas", "envelope"]} size="sm" />
            </AniLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default Sidebar;
