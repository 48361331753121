/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "../../scss/reset.css";
import "../../scss/mixins.scss";
import "../../scss/base.scss";
// helpers
import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Components ///
import { HamburgerVortexReverse } from "react-animated-burgers";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../Logo/Logo";

// font awesome
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faUser,
  faToolbox,
  faFolderOpen,
  faEnvelope,
  faCog,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import layoutStyles from "./Layout.module.scss";
config.autoAddCss = false;
library.add(
  fab,
  faUser,
  faCheck,
  faEnvelope,
  faToolbox,
  faFolderOpen,
  faCog,
  faPaperPlane,
  faTrash
);

// css
class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.toggleButton = this.toggleButton.bind(this);
    this.closeMobileNav = this.closeMobileNav.bind(this);
  }
  toggleButton = () => {
    if (window.innerWidth < 678) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };
  closeMobileNav = e => {
    if (window.innerWidth > 678 && this.state.isOpen) {
      console.log("getting closed");
      this.setState({
        isOpen: false,
      });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.closeMobileNav);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.closeMobileNav);
  }
  render() {
    return (
      <>
        <div className={layoutStyles.page_wrap}>
          <nav>
            <div className={layoutStyles.socials}>
              <a
                href="https://github.com/malcolmu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "github"]} size="sm" />
              </a>
              <a
                href="https://www.linkedin.com/in/malcolmjjfernandes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} size="sm" />
              </a>
            </div>
            <AniLink fade to="/" duration={1}>
              <Logo className />
            </AniLink>
            <HamburgerVortexReverse
              className={layoutStyles.toggleMenu}
              isActive={this.state.isOpen}
              toggleButton={this.toggleButton}
              barColor="#e7d5bf"
            />
          </nav>
          <Sidebar
            toggleSidebar={this.toggleButton}
            isOpen={this.state.isOpen}
          />
          <main id="main-panel">
            <div className={layoutStyles.container}>{this.props.children}</div>
          </main>
        </div>
      </>
    );
  }
}

export default Layout;
