import React from "react";

import { css } from "@emotion/core";
// import detailed_logo from "./detailed_logo";
import logoPaths from "./svg_logo";
import {
  gsap,
  SteppedEase,
  // CSSPlugin,
  // Back
} from "gsap/all";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";
import logoStyles from "./Logo.module.scss";
// const plugins = [CSSPlugin];

// const logoPaths = [
//   "M92.435 64.133L73.92 64.12l-18.483-.012 9.248 16.041 9.23 16.013 9.27-16.03zM73.957 32.078l-18.517-.012-18.483-.012 9.248 16.042 9.231 16.013 9.269-16.03z",
//   "M92.435 64.085l-18.516.012-18.483.012 9.248-16.042 9.23-16.013 9.27 16.03zM110.956 32.054l-18.517.012-18.482.012 9.247-16.041L92.435.024l9.269 16.03zM73.957 32.03l-18.517.012-18.483.012 9.248-16.041L55.436 0l9.269 16.03z",
//   "M55.478 64.085l-18.516.012-18.483.012 9.247-16.042 9.231-16.013 9.269 16.03zM37 96.139l-18.517.012L0 96.163l9.248-16.042 9.23-16.012 9.27 16.03z",
//   "M55.52 64.109l-18.517-.012-18.482-.012 9.247 16.041L37 96.14l9.269-16.03zM166.475.048L147.96.036 129.476.024l9.248 16.042 9.231 16.012 9.269-16.03zM129.434 64.109l-18.516-.012-18.483-.012 9.248 16.041 9.231 16.013 9.269-16.03z",
//   "M147.955 32.078l-18.516-.012-18.483-.012 9.247 16.042 9.231 16.013 9.27-16.03zM110.956 32.054l-18.517-.012-18.482-.012 9.247 16.042 9.231 16.013 9.269-16.03z",
//   "M147.955 32.078l-18.516.013-18.483.012 9.247-16.042L129.434.048l9.27 16.03zM129.434 64.109l-18.516.012-18.483.012 9.248-16.042 9.231-16.013 9.269 16.03zM184.996 32.078l-18.516.013-18.483.012 9.248-16.042 9.23-16.013 9.27 16.03zM166.517 64.133l-18.516.012-18.483.012 9.248-16.042 9.23-16.012 9.27 16.03z",
//   "M203.517.048L185 .036 166.517.024l9.248 16.042 9.231 16.012 9.269-16.03z"
// ];

function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}
class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.myPaths = [];
    this.masterTl = new gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
      repeatRefresh: true,
    });
  }
  componentDidMount() {
    // var stagger_opts_to = ;
    function setPaths(paths) {
      var tl = new gsap.timeline({
        id: "opener",
      });

      paths.map(path => {
        return tl.set(path, {
          // x: "+=" + getRandom(-2000, 2000),
          // y: "+=" + getRandom(-2000, 2000),
          transformOrigin: "center center",
          opacity: 0,
          ease: "power4.in",
          rotation: 365 * getRandom(1, 3),
        });
      });
      tl.to(paths, {
        id: "normalise",
        x: 0,
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 4,
        rotation: 0,

        stagger: 0.001,
      });
      return tl;
    }
    setPaths(this.myPaths);
    this.masterTl.play();

    this.masterTl

      .to(this.myPaths, {
        // x: "+=" + getRandom(-2, 2),
        // y: "+=" + getRandom(-2, 2),
        // transform:"skew(" + getRandom(0, 20) + "deg," + getRandom(0, 20) + "deg)",
        delay: 4,
        duration: 1,
        ease: SteppedEase.easeInOut,
        stagger: 0.5,
        rotation: 360,
      })
      .to(
        this.myPaths,
        {
          fill: "#ff7601",
          duration: 2,
          stagger: { amount: 1, from: "center", grid: "auto" },
          ease: SteppedEase.easeInOut,
        },
        ">"
      )
      .to(this.myPaths, {
        delay: 2,
        duration: 4,
        ease: "power4.easeInOut",
        rotation: -360,
      })
      .to(this.myPaths, {
        strokeWidth: 2,
        duration: 4,
        stroke: "#1f2528",
      })
      .to(this.myPaths, {
        duration: 3,
        rotation: 360 * 1,
        delay: 1,
        stroke: "#ff7601",
        strokeWidth: 1,
        fill: "#1f2528",
        stagger: 0.4,
        ease: "slow(0.5, 0.7, false)",
      });
  }
  render() {
    return (
      <>
        <svg
          css={
            this.props.center &&
            css`
              position: absolute;
              transform: translate(-50%, -50%);
            `
          }
          className={logoStyles.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 197.84798 102.79235"
        >
          {logoPaths.map((path, i) => {
            return (
              <path ref={path => (this.myPaths[i] = path)} key={i} d={path} />
            );
          })}
        </svg>
      </>
    );
  }
}
export default Logo;
