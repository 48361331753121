const logoPaths = [
  "M92.435 64.133L73.92 64.12l-18.483-.012 9.248 16.041 9.23 16.013 9.27-16.03zM73.957 32.078l-18.517-.012-18.483-.012 9.248 16.042 9.231 16.013 9.269-16.03z",
  "M92.435 64.085l-18.516.012-18.483.012 9.248-16.042 9.23-16.013 9.27 16.03zM110.956 32.054l-18.517.012-18.482.012 9.247-16.041L92.435.024l9.269 16.03zM73.957 32.03l-18.517.012-18.483.012 9.248-16.041L55.436 0l9.269 16.03z",
  "M55.478 64.085l-18.516.012-18.483.012 9.247-16.042 9.231-16.013 9.269 16.03zM37 96.139l-18.517.012L0 96.163l9.248-16.042 9.23-16.012 9.27 16.03z",
  "M55.52 64.109l-18.517-.012-18.482-.012 9.247 16.041L37 96.14l9.269-16.03zM166.475.048L147.96.036 129.476.024l9.248 16.042 9.231 16.012 9.269-16.03zM129.434 64.109l-18.516-.012-18.483-.012 9.248 16.041 9.231 16.013 9.269-16.03z",
  "M147.955 32.078l-18.516-.012-18.483-.012 9.247 16.042 9.231 16.013 9.27-16.03zM110.956 32.054l-18.517-.012-18.482-.012 9.247 16.042 9.231 16.013 9.269-16.03z",
  "M147.955 32.078l-18.516.013-18.483.012 9.247-16.042L129.434.048l9.27 16.03zM129.434 64.109l-18.516.012-18.483.012 9.248-16.042 9.231-16.013 9.269 16.03zM184.996 32.078l-18.516.013-18.483.012 9.248-16.042 9.23-16.013 9.27 16.03zM166.517 64.133l-18.516.012-18.483.012 9.248-16.042 9.23-16.012 9.27 16.03z",
  "M203.517.048L185 .036 166.517.024l9.248 16.042 9.231 16.012 9.269-16.03z",
];
export default logoPaths;
